<template>
  <a-descriptions
    :column="1"
    class="description-overflow-hidden description-item-12px"
  >
    <a-descriptions-item label="名称">
      <content-tooltip
        v-if="detail.name"
        :title="detail.name"
        :body="detail.name"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="制造商">
      <content-tooltip
        v-if="detail.vendor"
        :title="$t(`hardware_vendor.${detail.vendor}`)"
        :body="$t(`hardware_vendor.${detail.vendor}`)"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="型号">
      <content-tooltip
        v-if="detail.model"
        :title="detail.model"
        :body="detail.model"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="SN">
      <content-tooltip
        v-if="detail.serial_number"
        :title="detail.serial_number"
        :body="detail.serial_number"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="固件版本" v-if="showMore">
      <content-tooltip
        v-if="detail.firmware_version"
        :title="detail.firmware_version"
        :body="detail.firmware_version"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="创建时间" v-if="showMore">
      <content-tooltip
        v-if="detail.created_at"
        :title="detail.created_at"
        :body="detail.created_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="更新时间" v-if="showMore">
      <content-tooltip
        v-if="detail.updated_at"
        :title="detail.updated_at"
        :body="detail.updated_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import ContentTooltip from '@/components/ContentTooltip'

export default {
  name: 'HardwareDescription',
  props: {
    detail: {
      type: Object
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentTooltip
  }
}
</script>

<style>

</style>
