<template>
  <a-range-picker
    id="datetime-range"
    :allow-clear="allowClear"
    :default-value="defaultValue"
    :disabled-date="disabledDate"
    :ranges="ranges"
    :show-time="showTime"
    :size="size"
    :format="`YYYY-MM-DD ${timeFormat}`"
    @change="change"
    @ok="ok"
  >
    <a-icon slot="suffixIcon" type="calendar"></a-icon>
  </a-range-picker>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatetimeRange',
  props: {
    allowClear: {
      type: Boolean,
      default: true
    },
    defaultValue: {
      type: Array
    },
    size: {
      type: String,
      default: 'default'
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    },
    ranges: {
      type: Object,
      default: () => {
        return {
          '5m': [moment().subtract(5, 'minute'), moment()],
          '15m': [moment().subtract(15, 'minute'), moment()],
          '1h': [moment().subtract(1, 'hour'), moment()],
          '3h': [moment().subtract(3, 'hour'), moment()],
          '6h': [moment().subtract(6, 'hour'), moment()],
          '1d': [moment().subtract(1, 'day'), moment()]
        }
      }
    }
  },
  data () {
    return {
      showTime: {
        format: this.timeFormat
      }
    }
  },
  methods: {
    disabledDate (date) {
      return date && date > moment().endOf('day')
    },
    change (dates) {
      if (!dates.length) {
        this.ok(dates)
      }
      this.$emit('change', dates)
    },
    ok (dates) {
      this.$emit('ok', dates)
    }
  }
}
</script>

<style lang="less">
#datetime-range {
  .ant-calendar-picker-input {
    height: 24px;
    width: 286px !important;
    padding: 0 11px;
    background: #f7f7f7;
    color: rgba(0,0,0,.5);
    font-size: 12px;
    border-width: 0;
  }
}
</style>
