<template>
  <div class="hardware-page">
    <a-row :gutter="16">
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="psus"
            :data-count="psuCount"
            type="psu"
            v-if="psus !== undefined"
          >
            <span slot="titleText">电源</span>
            <monitor-icon
              slot="itemIcon"
              icon="power"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="fans"
            :data-count="fanCount"
            type="fan"
            v-if="fans !== undefined"
          >
            <span slot="titleText">风扇</span>
            <monitor-icon
              slot="itemIcon"
              icon="fan"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="temps"
            :data-count="tempCount"
            type="temp"
            v-if="temps !== undefined"
          >
            <span slot="titleText">温度</span>
            <monitor-icon
              slot="itemIcon"
              icon="temperature"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="disks"
            :data-count="diskCount"
            source-type="storage"
            type="disk"
            v-if="disks !== undefined"
          >
            <span slot="titleText">物理磁盘</span>
            <monitor-icon
              slot="itemIcon"
              icon="disk"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="enclosures"
            :data-count="enclosureCount"
            type="enclosure"
            v-if="enclosures !== undefined"
          >
            <span slot="titleText">Enclosure</span>
            <monitor-icon
              slot="itemIcon"
              icon="enclosure"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="controllers"
            :data-count="controllerCount"
            type="controller"
            v-if="controllers !== undefined"
          >
            <span slot="titleText">控制器</span>
            <monitor-icon
              slot="itemIcon"
              icon="controller"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="pools"
            :data-count="poolCount"
            type="pool"
            v-if="pools !== undefined"
          >
            <span slot="titleText">Pool</span>
            <monitor-icon
              slot="itemIcon"
              icon="pool"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="luns"
            :data-count="lunCount"
            type="lun"
            v-if="luns !== undefined"
          >
            <span slot="titleText">LUN</span>
            <monitor-icon
              slot="itemIcon"
              icon="lun"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
      <a-col :span="12" style="margin-bottom: 16px">
        <div class="module-container bg-module-container" style="height: 270px">
          <hardware-resource-card
            :data-source="volumes"
            :data-count="volumeCount"
            type="volume"
            v-if="volumes !== undefined"
          >
            <span slot="titleText">Volume</span>
            <monitor-icon
              slot="itemIcon"
              icon="volume"
              style="font-size: 24px"
            ></monitor-icon>
          </hardware-resource-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { humanizeValue } from '@/utils'
import {
  getStoragePSUList,
  getStorageFanList,
  getStorageTemperatureList,
  getStoragePhysicalDiskList,
  getStorageEnclosureList,
  getStorageControllerList,
  getStoragePoolList,
  getStorageMonitorItemList,
  getStorageLUNList,
  getStorageVolumeList
} from '@/api/storage'
import HardwareResourceCard from '@/components/source-detail-page/HardwareResourceCard'
import MonitorIcon from '@/components/icon/MonitorIcon'

export default {
  name: 'HardwarePage',
  components: {
    HardwareResourceCard,
    MonitorIcon
  },
  props: {
    detail: {
      type: Object
    }
  },
  data () {
    return {
      psus: [],
      psuCount: {
        normal: 0,
        abnormal: 0
      },
      fans: [],
      fanCount: {
        normal: 0,
        abnormal: 0
      },
      temps: [],
      tempCount: {
        normal: 0,
        abnormal: 0
      },
      disks: [],
      diskCount: {
        normal: 0,
        abnormal: 0
      },
      enclosures: [],
      enclosureCount: {
        normal: 0,
        abnormal: 0
      },
      controllers: [],
      controllerCount: {
        normal: 0,
        abnormal: 0
      },
      pools: [],
      poolCount: {
        normal: 0,
        abnormal: 0
      },
      luns: [],
      lunCount: {
        normal: 0,
        abnormal: 0
      },
      volumes: [],
      volumeCount: {
        normal: 0,
        abnormal: 0
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.psus = []
      this.psuCount = {
        normal: 0,
        abnormal: 0
      }
      this.fans = []
      this.fanCount = {
        normal: 0,
        abnormal: 0
      }
      this.temps = []
      this.tempCount = {
        normal: 0,
        abnormal: 0
      }
      this.disks = []
      this.diskCount = {
        normal: 0,
        abnormal: 0
      }
      this.enclosures = []
      this.enclosureCount = {
        normal: 0,
        abnormal: 0
      }
      this.controllers = []
      this.controllerCount = {
        normal: 0,
        abnormal: 0
      }
      this.pools = []
      this.poolCount = {
        normal: 0,
        abnormal: 0
      }
      this.luns = []
      this.lunCount = {
        normal: 0,
        abnormal: 0
      }
      this.volumes = []
      this.volumeCount = {
        normal: 0,
        abnormal: 0
      }

      getStoragePSUList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: 0,
              label: '功率',
              unit: item.unit ? item.unit : 'W',
              power: 0,
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'psu')
            getStorageMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value =
                res.data && res.data.data.length ? res.data.data[0].value : '-'
              obj.power = obj.value
            })
            if (item.status === 'normal') this.psuCount.normal++
            else this.psuCount.abnormal++
            this.psus.push(obj)
          })
        } else this.psus = []
      })

      getStorageFanList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: '-',
              label: '转速',
              unit: item.unit ? item.unit : 'rpm',
              speed: '-',
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'fan')
            getStorageMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value =
                res.data && res.data.data.length ? res.data.data[0].value : '-'
              obj.speed = obj.value
            })
            if (item.status === 'normal') this.fanCount.normal++
            else this.fanCount.abnormal++
            this.fans.push(obj)
          })
        } else this.fans = []
      })

      getStorageTemperatureList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: '-',
              label: '温度',
              unit: item.unit ? item.unit : '°C',
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const key = this.getKeys(item.index, 'temp')
            getStorageMonitorItemList(this.detail.id, { key }).then(res => {
              obj.value =
                res.data && res.data.data.length ? res.data.data[0].value : '-'
            })
            if (item.status === 'normal') this.tempCount.normal++
            else this.tempCount.abnormal++
            this.temps.push(obj)
          })
        } else this.temps = []
      })

      getStoragePhysicalDiskList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          data.data.forEach(item => {
            const obj = {
              id: item.id,
              name: item.name,
              index: item.index,
              status: item.status,
              value: '-',
              label: '容量',
              used: '-',
              size: '-',
              usage: 0,
              unit: item.unit ? item.unit : 'B',
              updated_at: item.updated_at,
              created_at: item.created_at
            }
            const keyArr = this.getKeys(item.index, 'disk')
            Promise.all(
              keyArr.map(async keyObj => {
                getStorageMonitorItemList(this.detail.id, {
                  key: keyObj.key
                }).then(res => {
                  const v =
                    res.data && res.data.data.length
                      ? res.data.data[0].value
                      : '-'
                  if (keyObj.name === 'usage') {
                    obj[keyObj.name] = v * 1
                  } else {
                    obj[keyObj.name] = v === '-' ? '-' : humanizeValue(v)
                    if (keyObj.name === 'size') obj.value = obj[keyObj.name]
                  }
                })
              })
            ).then(() => {
              if (item.status === 'normal') this.diskCount.normal++
              else this.diskCount.abnormal++
              this.disks.push(obj)
            })
          })
        } else this.disks = []
      })

      getStorageEnclosureList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          this.enclosures = data.data
          data.data.forEach(item => {
            if (item.status === 'normal') this.enclosureCount.normal++
            else this.enclosureCount.abnormal++
          })
        } else this.enclosures = []
      })

      getStorageControllerList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          this.controllers = data.data
          data.data.forEach(item => {
            if (item.status === 'normal') this.controllerCount.normal++
            else this.controllerCount.abnormal++
          })
        } else this.controllers = []
      })

      getStoragePoolList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          this.pools = data.data
          data.data.forEach(item => {
            if (item.status === 'normal') this.pools.normal++
            else this.pools.abnormal++
          })
        } else this.pools = []
      })

      getStorageLUNList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          this.luns = data.data
          data.data.forEach(item => {
            if (item.status === 'normal') this.lunCount.normal++
            else this.lunCount.abnormal++
          })
        } else this.luns = []
      })

      getStorageVolumeList(this.detail.id).then(res => {
        const data = res.data
        if (data && data.data.length) {
          this.volumes = data.data
          data.data.forEach(item => {
            if (item.status === 'normal') this.volumeCount.normal++
            else this.volumeCount.abnormal++
          })
        } else this.volumes = []
      })
    },
    getKeys (index, type) {
      if (type === 'psu') return `sensor.psu.power[${index}]`
      if (type === 'fan') return `sensor.fan.speed[${index}]`
      if (type === 'temp') return `sensor.temp.value[${index}]`
      if (type === 'memory') return `memory.size[${index}]`
      if (type === 'disk') {
        return [
          { key: `physicaldisk.size[${index}]`, name: 'size' },
          { key: `physicaldisk.used[${index}]`, name: 'used' },
          { key: `physicaldisk.pused[${index}]`, name: 'usage' }
        ]
      }
    }
  }
}
</script>

<style lang="less">
.hardware-page {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    margin-right: 1px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }
  .bg-module-container {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0;
      width: 41.67%;
      border-right: 1px solid #f6fbfe;
      background: #f6fbfe;
    }
  }
}
</style>
