import request from '@/utils/request'

const urlPrefix = '/itsm'

export function createServiceSecurityITSM (data) {
  return request.post(`${urlPrefix}/service-security`, data)
}

export function createAlertITSM (data) {
  return request.post(`${urlPrefix}/alert`, data)
}

export function createEventITSM (data) {
  return request.post(`${urlPrefix}/event`, data)
}
