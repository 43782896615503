<template>
  <div class="circle-status" :style="{ textAlign: 'center' }">
    <a-progress
      type="circle"
      :percent="100"
      :width="width"
      :stroke-width="strokeWidth"
      :stroke-color="statusColor"
      style="margin-bottom: 4px"
    >
      <template #format>
        <slot name="circleText">
          <div
            :style="{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
              marginTop: '4px',
              color: statusColor
            }"
          >
            {{ $t(`source_status.${detail.value ? detail.value : 'unknown'}`)}}
          </div>
        </slot>
      </template>
    </a-progress>
    <div v-if="showBottomText">
      <div class="name-icon" :style="{ background: statusColor }"></div>
      {{ $t(`hardware_type.${detail.name}`)}}
    </div>
  </div>
</template>

<script>
import { StatusColor } from '@/utils'

export default {
  name: 'CircleStatus',
  props: {
    detail: {
      type: Object
    },
    width: {
      type: Number,
      default: 98
    },
    strokeWidth: {
      type: Number,
      default: 16
    },
    showBottomText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    statusColor () {
      return StatusColor(this.detail ? this.detail.value : 'unknown')
    }
  }
}
</script>

<style lang="less">
.circle-status {
  .name-icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 1px 0 -9px;
  }
}
</style>
