import { render, staticRenderFns } from "./SourceStatusTag.vue?vue&type=template&id=6c14643b"
import script from "./SourceStatusTag.vue?vue&type=script&lang=js"
export * from "./SourceStatusTag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports