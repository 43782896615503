<template>
  <a-tag :color="color" style=" width: 40px; height: 22px; padding: 0; text-align: center;">{{ $t(`source_status.${status}`) }}</a-tag>
</template>

<script>
import { greenColor, redColor, grayColor, orangeColor } from '@/utils/const'

export default {
  name: 'SourceStatusTag',
  props: {
    status: {
      type: String
    }
  },
  computed: {
    color () {
      if (this.status === 'normal') return greenColor
      else if (this.status === 'abnormal') return redColor
      else if (this.status === 'alert') return orangeColor
      return grayColor
    }
  }
}
</script>
