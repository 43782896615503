<template>
  <div class="hardware-resource-card">
    <a-row :gutter="16">
      <a-col :span="10">
        <div style="height: 544x; line-height:44px; margin: -2px 0 0 -10px">
          <div
            style="
              font-weight: 500;
              font-size: 18px;
              text-align: center;
              margin-top: -8px;
            "
          >
            <slot name="titleText"></slot>
          </div>
        </div>
        <div class="main-number" :style="{ color: greenColor }">{{ normalRate }}</div>
        <div style="color: #838383; text-align: center; margin: -6px 0 0 -12px">
          正常率
        </div>
        <a-progress
          :percent="normalRate"
          :show-info="false"
          style="padding: 14px 8px 4px 8px"
          :stroke-color="greenColor"
          :stroke-width="10"
        ></a-progress>
        <div
          style="
            display: flex;
            margin-top: 16px;
            justify-content: space-around;
            line-height: 30px;
          "
        >
          <div style="text-align: center">
            <div style="color: #838383">总数</div>
            <div :style="{ fontWeight: 500, fontSize: '18px' }">{{ dataSource.length }}</div>
          </div>
          <div style="text-align: center">
            <div style="color: #838383">正常数</div>
            <div
              :style="{ fontWeight: 500, fontSize: '18px', color: greenColor }"
            >
              {{ dataCount.normal }}
            </div>
          </div>
          <div style="text-align: center">
            <div style="color: #838383">异常数</div>
            <div
              :style="{ fontWeight: 500, fontSize: '18px', color: redColor }"
            >
              {{ dataCount.abnormal }}
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-left: -16px;"><a @click="showTable" style="font-size: 12px; opacity: .85; line-height: 36px;">查看全部</a></div>
      </a-col>
      <a-col :span="14" class="border-col">
        <div class="item-container" v-if="dataSource.length">
          <div v-for="(item, index) in dataSource.filter((_, i) => i < 2)" :key="type + index">
            <div class="item">
              <div><slot name="itemIcon"></slot></div>
              <div class="item-no" style="font-weight: 500;">
                <!-- {{ index > 8 ? index + 1 : `0${index + 1}` }} -->
              </div>
              <div class="item-name">
                <content-tooltip v-if="item.name" :title="item.name" :body="item.name"></content-tooltip>
                <span v-else>-</span>
              </div>
            </div>
            <div class="item-body">
              <div style="float: left;" v-if="item.label">{{ item.label }}</div>
              <div style="float: right; font-weight: 600;" v-if="item.label">{{ item.value }} {{ ((item.value && item.value !== '-') || item.value === 0) ? item.unit : ''}}</div>
              <div style="clear: both;"></div>
              <div style="float: left; margin-top: 10px;">状态:</div>
              <div style="float: right; margin-top: 10px;">
                <source-status-tag :status="item.status"></source-status-tag>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="height: 240px; position: relative; overflow: hidden;">
          <empty-component
            :imgUrl="require('@/assets/images/info_empty.png')"
            :body-style="{
              height: '118px',
              marginTop: '42px'
            }"
            :description-style="{
              marginTop: '-8px',
              marginRight: '8px'
            }"
            description="暂无数据"
          ></empty-component>
        </div>
      </a-col>
    </a-row>

    <a-modal :visible="visible" :title="title" :destroy-on-close="true" :footer="null" :width="900" @cancel="handleCancel">
      <psu-table ref="psuTable" :data-source="dataSource" v-if="type==='psu'"></psu-table>
      <fan-table ref="fanTable" :data-source="dataSource" v-if="type==='fan'"></fan-table>
      <temp-table ref="tempTable" :data-source="dataSource" v-if="type==='temp'"></temp-table>
      <disk-table ref="diskTable" :data-source="dataSource" v-if="type==='disk'" :sourceType="sourceType"></disk-table>
      <memory-table ref="memoryTable" :data-source="dataSource" v-if="type==='memory'"></memory-table>
      <cpu-table ref="cpuTable" :data-source="dataSource" v-if="type==='cpu'"></cpu-table>
      <disk-array-table ref="diskArrayTable" :data-source="dataSource" v-if="type==='disk_array'"></disk-array-table>
      <enclosure-table ref="enclosureTable" :data-source="dataSource" v-if="type==='enclosure'"></enclosure-table>
      <controller-table ref="controllerTable" :data-source="dataSource" v-if="type==='controller'"></controller-table>
      <pool-table ref="poolTable" :data-source="dataSource" v-if="type==='pool'"></pool-table>
      <lun-table ref="lunTable" :data-source="dataSource" v-if="type==='lun'"></lun-table>
      <volume-table ref="volumeTable" :data-source="dataSource" v-if="type==='volume'"></volume-table>
    </a-modal>
  </div>
</template>

<script>
import { redColor, greenColor, blueColor } from '@/utils/const.js'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import ContentTooltip from '@/components/ContentTooltip.vue'

export default {
  name: 'HardwareResourceCard',
  components: {
    EmptyComponent: () => import('@/components/EmptyComponent.vue'),
    'psu-table': () => import('@/components/table/PSUSimpleTable.vue'),
    'fan-table': () => import('@/components/table/FanSimpleTable.vue'),
    'temp-table': () => import('@/components/table/TemperatureSimpleTable.vue'),
    'disk-table': () => import('@/components/table/DiskSimpleTable.vue'),
    'memory-table': () => import('@/components/table/MemorySimpleTable.vue'),
    'cpu-table': () => import('@/components/table/CPUSimpleTable.vue'),
    'disk-array-table': () => import('@/components/table/DiskArraySimpleTable.vue'),
    'enclosure-table': () => import('@/components/table/EnclosureSimpleTable.vue'),
    'controller-table': () => import('@/components/table/ControllerSimpleTable.vue'),
    'pool-table': () => import('@/components/table/PoolSimpleTable.vue'),
    'lun-table': () => import('@/components/table/LUNSimpleTable.vue'),
    'volume-table': () => import('@/components/table/VolumeSimpleTable.vue'),
    SourceStatusTag,
    ContentTooltip
  },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    dataCount: {
      type: Object,
      default: () => {
        return {
          normal: 0,
          abnormal: 0
        }
      }
    },
    sourceType: {
      type: String
    },
    type: {
      type: String
    }
  },
  computed: {
    normalRate () {
      if (this.dataCount.normal) return Math.floor(this.dataCount.normal / this.dataSource.length * 100)
      return 0
    },
    title () {
      if (this.type === 'psu') return '电源详情'
      if (this.type === 'fan') return '风扇详情'
      if (this.type === 'temp') return '温度详情'
      if (this.type === 'disk') return '物理磁盘详情'
      if (this.type === 'disk_array') return '磁盘阵列详情'
      if (this.type === 'memory') return '内存详情'
      if (this.type === 'cpu') return 'CPU详情'
      if (this.type === 'enclosure') return 'Enclosure详情'
      if (this.type === 'controller') return '控制器详情'
      if (this.type === 'pool') return 'Pool详情'
      if (this.type === 'lun') return 'LUN详情'
      if (this.type === 'volume') return 'Volume详情'
      return '电源详情'
    }
  },
  data () {
    return {
      redColor,
      greenColor,
      blueColor,
      visible: false
    }
  },
  mounted () {
  },
  methods: {
    showTable () {
      this.visible = true
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.hardware-resource-card {
  position: relative;

  .main-number {
    font-size: 40px;
    text-align: center;
    position: relative;
    margin-top: -8px;

    &::after {
      content: '%';
      font-size: 14px;
      color: #838383;
      right: 0;
      margin-left: 2px;
    }
  }

  .border-col {
    position: relative;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   height: 1px;
    //   background: #e5e5e5;
    //   left: -2px;
    //   right: -8px;
    //   top: 50%;
    // }

    .item-container {
      height: 252px;
      display: grid;
      grid-template-rows: 1fr  1fr;
      grid-template-columns: 1fr;

      & > div {
        border-radius: 8px;
        // display: flex;

        .item {
          display: grid;
          grid-template-columns: 22px 4px auto;
          grid-gap: 8px;
          line-height: 28px;

          .item-no {
            font-weight: 500;
          }

          .item-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .item-body {
          padding: 8px 16px 16px  16px;
        }
      }

      // & > div:nth-of-type(2) {
      //   .item {
      //     padding-top: 16px
      //   }
      // }
    }
  }
}
</style>
