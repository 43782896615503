<template>
  <a-descriptions
    :column="2"
    class="description-overflow-hidden description-item-12px"
  >
    <a-descriptions-item label="采购日期" :span="2">
      <content-tooltip
        v-if="detail.purchased_at"
        :title="detail.purchased_at"
        :body="detail.purchased_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="过保日期" :span="2">
      <content-tooltip
        v-if="detail.expires_at"
        :title="detail.expires_at"
        :body="detail.expires_at"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="负责人">
      <content-tooltip
        v-if="detail.manager"
        :title="detail.manager"
        :body="detail.manager"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="采购金额">
      <content-tooltip
        v-if="detail.price"
        :title="detail.price"
        :body="detail.price"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
    <a-descriptions-item label="位置" :span="2">
      <content-tooltip
        v-if="detail.location"
        :title="detail.location"
        :body="detail.location"
      ></content-tooltip>
      <span v-else>-</span>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import ContentTooltip from '@/components/ContentTooltip'

export default {
  name: 'AssetDescription',
  props: {
    detail: {
      type: Object
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentTooltip
  }
}
</script>

<style>

</style>
