var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-status",style:({ textAlign: 'center' })},[_c('a-progress',{staticStyle:{"margin-bottom":"4px"},attrs:{"type":"circle","percent":100,"width":_vm.width,"stroke-width":_vm.strokeWidth,"stroke-color":_vm.statusColor},scopedSlots:_vm._u([{key:"format",fn:function(){return [_vm._t("circleText",function(){return [_c('div',{style:({
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            marginTop: '4px',
            color: _vm.statusColor
          })},[_vm._v(" "+_vm._s(_vm.$t(`source_status.${_vm.detail.value ? _vm.detail.value : 'unknown'}`))+" ")])]})]},proxy:true}],null,true)}),(_vm.showBottomText)?_c('div',[_c('div',{staticClass:"name-icon",style:({ background: _vm.statusColor })}),_vm._v(" "+_vm._s(_vm.$t(`hardware_type.${_vm.detail.name}`))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }